// extracted by mini-css-extract-plugin
export var collaborators = "research-module--collaborators---PaX-";
export var container = "research-module--container--s7cD9";
export var defaultButton = "research-module--default-button--ZE0dN";
export var expandButton = "research-module--expand-button--yjzc5";
export var homeLinks = "research-module--home-links--0BNEV";
export var html = "research-module--html--er+tP";
export var journalTitle = "research-module--journal-title--81D1S";
export var moreInfo = "research-module--more-info--yaPJu";
export var pageTitle = "research-module--page-title--dclCZ";
export var plusSign = "research-module--plus-sign--3u6vd";
export var projectBlock = "research-module--project-block--b9i0b";
export var projectImage = "research-module--project-image--6nvJG";
export var projectInfo = "research-module--project-info--FrJSb";
export var projectTitle = "research-module--project-title--mQ942";
export var pubDate = "research-module--pub-date--zjLAE";